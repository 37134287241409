/*.settings-page ion-item {
    background: var(--ion-color-light);
    border-top: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    padding: 0.2rem;
}*/

.settings-list {
    background: fff;
}

.settings-list ion-item {
    --ion-background-color: var(--ion-color-light) !important;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #E4E4E4;
    padding: 0;
}
.settings-list ion-item p {
    margin: 0;
}
.settings-list ion-item ion-icon {
    border-radius: 5px;
    padding: 0.2rem;
    font-size: 1.4rem;
    margin-right: 0.5rem;
    /*--setting-item-color: white;
    background-color: var(--ion-color-light); */
    color: var(--ion-color-dark);
}
.small-text {
    font-size: 0.9rem;
}
.settings-avatar {
    padding: 0.5rem;
}
.settings-avatar ion-img {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 500px;
}
.settings-actions ion-icon {
    padding: 0.5rem;
    background-color: var(--ion-color-primary);
    border-radius: 500px;
}

.account-img-box {
    position: absolute;
    width: 80%;
    height: 230px;
    left: 0%;
    top: 0px;
}

.wrapper {
    max-width: 960px; 
  
    padding-right: 10px;
    padding-left: 10px;
    top: 100px;
    position: relative;

}

.account-img-box {
    position: relative;
    width: 48%;
    height: 50%;
    left: 25%;
    top: 20px;
    /*background: url(image.png);*/
    /*border: 3px solid #ff0000;*/
    filter: drop-shadow(0px 22px 69px #FFE4D6);
}

.account-title {
    position: absolute;
    width: 100%;
    top: 150px;
    height: 28px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    font-weight: bold;
    line-height: 28px;
}
.account-email {
    position: absolute;
    width: 100%;
    top: 180px;
    height: 18px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
}
.account-role {
    position: absolute;
    width: 100%;
    top: 200px;
    height: 18px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
}

.account-content-box {
    position: absolute;
    width: 100%;
    /*height: 398px;*/
    top: 240px;
}
.account-footer-box {
    position: absolute;
    width: 80%;
    /*height: 398px;*/
    left: 5%;
    bottom: 10px;

}
