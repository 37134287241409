.leads-item ion-label {
    /*border-left: 2px solid var(--ion-color-primary);*/
    padding-left: 10px;
  }

.ios ion-list {
  margin-bottom: 10px;
}

.md ion-list {
  border-top: 1px solid var(--ion-color-step-150, #d7d8da);
  padding: 0;
}

rce-citem-body--bottom-status span{
  background: var(--ion-color-warning);
  border-radius:100%;
  color:#fff;
  font-size:12px;
  font-weight:700;
  text-align:center}

.circle-warning {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: var(--ion-color-warning);
  border-radius: 50%;
}

.circle-success {
  display: flex;
  width: 60px;
  height: 60px;
  background-color: var(--ion-color-success);
  border-radius: 50%;
}

.text {
  margin: auto;
}

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}