/*ion-item ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}*/

.dealerships-item ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}

.d-wrapper {
  max-width: 960px; 

  padding-right: 10px;
  padding-left: 10px;
}

.top-border-line {
  margin-top: 10px;
  border-top: 1px solid #e9eaeb;
}

ion-list ion-icon {
  color: var(--ion-color-secondary-tint);
}


.header-map capacitor-google-map {
	width: 100%;
	height: 100%;
	/*margin-top: -5rem;*/
	border-bottom: 5px solid var(--ion-color-primary);
}

.dealer-card {
  background-size: 100%;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  /*width: 105vw!important;
  height: 100vh!important;*/
  /*margin-left: -10%!important;*/
  background-attachment: fixed!important;
  /*margin-top: -10%!important;*/
  background-position: center center!important;
  background: linear-gradient(rgba(201, 11, 27, 0.75), rgba(02, 02, 02, 0.75)), url('../assets/images/hino_dealer.png') no-repeat center;
}

.builder-card {
  background-size: 100%;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  /*width: 105vw!important;
  height: 100vh!important;*/
  /*margin-left: -10%!important;*/
  background-attachment: fixed!important;
  /*margin-top: -10%!important;*/
  background-position: center center!important;
  background: linear-gradient(rgba(201, 11, 27, 0.75), rgba(02, 02, 02, 0.75)), url('../assets/images/hino_builder.png') no-repeat center;
}