.header-image img {
	width: 100%;
	height: 100%;
	margin-top: -5rem;
	border-bottom: 5px solid var(--ion-color-primary);
}

.tl-wrapper {
    max-width: 960px; 
  
    padding-right: 10px;
    padding-left: 10px;

}

.header-image .header-info {
	position: absolute;
	right: 0px;
	top: 10rem;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	padding: 1rem;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.header-image .header-info h1 {
	font-size: 1.1rem;
	padding: 0;
	margin: 0;
	color: var(--ion-color-primary);
}
.header-image .header-info p {
	font-size: 0.9rem;
	padding: 0;
	margin: 0;
}

.detail-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px 0;
	padding: 10px;
}

.detail-container-load {
	margin-top: 1em;
	color: white;
}

.detail-container-document {
	margin: 1em 0;
}

.detail-container-document .react-pdf__Document {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.detail-container-document .react-pdf__Page {
	max-width: calc(100% - 2em);
	box-shadow: 0 0 8px rgba(0,0,0,0.5);
	margin: 1em;
}

.detail-container-document .react-pdf__Page canvas {
	max-width: 100%;
	height: auto !important;
}

.detail-container-document .react-pdf__message {
	padding: 20px;
	color: white;
}

.canvas {
    height: 30vh;
    width: 100%
}
