/* chat component */
.messages-wrapper {
    padding: 30px;
    margin-bottom: 100px;
    background: var(--ion-color-light);
    /*background-color: rgba(157,240,201,.75);*/
  }
  .chat-bubble {
    border-radius: 20px 20px 20px 0;
    padding: 15px;
    background-color: var(--ion-color-secondary);
    color: #1c2c4c;
    width: max-content;
    max-width: calc(100% - 50px);
    box-shadow: -2px 2px 1px 1px var(--ion-color-secondary);
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .chat-bubble.right {
    margin-left: auto;
    border-radius: 20px 20px 0 20px;
    background-color: var(--ion-color-success);
    box-shadow: -2px 2px 1px 1px var(--ion-color-success-shade);
  }
  .chat-bubble__left {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-name {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #1c2c4c;
  }
  .user-message {
    word-break: break-all;
  }
  .message-time {
    display: block;
    text-align: right;
  }
  
  /* send message */
  .send-message {
    position: fixed;
    bottom: 0px;
    width: 100%;
    /*padding: 20px 30px;*/
    padding-bottom: 30px;
    padding-top: 20px;
    background: white;
    /*background-color: var(--ion-color-light-shade);*/
    display: flex;
  }
  /*.send-message > ion-textarea {
    height: 40px;
    padding: 10px 10px;
    border-radius: 5px 0 0 5px;
    border: none;
    flex-grow: 1;
    background-color: white;
    color: #1c2c4c;
    font-size: 1rem;
  }
  .send-message > ion-textarea:placeholder {
    color: #ddd;
  }
  .send-message > :is(ion-textarea, ion-button):focus {
    outline: none;
    border-bottom: 1px solid #7cc5d9;
  }*/
  .send-message > ion-button {
    width: 70px;
    height: 40px;
    padding: 5px 10px;
    border-radius: 0 5px 5px 0;
    color: var(--ion-color-light);
    border: 1px solid var(--ion-color-primary-shade);
    background-color: var(--ion-color-primary);
    font-weight: 600;
  }

  ion-input.custom {
    --background: #373737;
    --color: #fff;
    --placeholder-color: #ddd;
    --placeholder-opacity: 0.8;
  
    --padding-bottom: 10px;
    --padding-end: 10px;
    --padding-start: 10px;
    --padding-top: 10px;
  }
  
  ion-input.custom.ios .input-bottom .helper-text,
  ion-input.custom.ios .input-bottom .counter,
  ion-input.custom.md .input-bottom .helper-text,
  ion-input.custom.md .input-bottom .counter {
    color: var(--ion-color-primary);
  }