.desktop {
  ion-router-outlet {
    margin-top: 56px;
    margin-bottom: 56px;
    --background: var(--ion-color-light);
  }

  .desktop-wrapper {
    --background: var(--ion-color-dark);
  }

  .desktop-page {
    --background: var(--ion-color-dark);
  }
  .desktop-content {
    --background: var(--ion-color-light);
  }

  .logo {
    max-height: 40px;
  }

  ion-toolbar {
    --background: var(--ion-color-dark);
  }

  .link {
    --color: var(--ion-color-light);
  }

  .active-link {
    --color: var(--ion-color-primary);
  }
}

.desktop-footer {
  width: 100%;
  color: #fff;
  font-weight: bold;
  background: var(--ion-color-dark);
  height: 56px;
  line-height: 56px;
  text-align: center;
  position: fixed;
  bottom: 0px;
}