#builder-list .builder-card {
    display: flex;
    flex-direction: column;
    /* Due to the fact the cards are inside of columns the margins don't overlap
     * properly so we want to remove the extra margin between cards
     */
  }
  #builder-list ion-col:not(:last-of-type) .builder-card {
    margin-bottom: 0;
  }
  #builder-list .builder-card .builder-item {
    --min-height: 85px;
  }
  #builder-list .builder-card .builder-item h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }
  #builder-list .builder-card .builder-item p {
    font-size: 13px;
    letter-spacing: 0.02em;
  }
  #builder-list .builder-card ion-card-header {
    padding: 0;
  }
  #builder-list .builder-card ion-card-content {
    flex: 1 1 auto;
    padding: 0;
  }
  #builder-list .ios ion-list {
    margin-bottom: 10px;
  }
  #builder-list .md ion-list {
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);
    padding: 0;
  }
  




















preferred-supplier ion-header.header-md:after {
    background: none;
}
preferred-supplier ion-header, preferred-supplier ion-toolbar {
    border: none !important;
    --border-style: none !important;
    border-color: none !important;
    border-bottom: none !important;
}
preferred-supplier ion-toolbar {
    background-color: #C90B1B;
    --background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23FFFFFF' fill-opacity='0.9' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.top-header {
    height: 8rem;
    margin-bottom: -8rem;
    border-end-start-radius: 60px !important;
    background-color: #C90B1B;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23FFFFFF' fill-opacity='0.9' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.avatar {
    border-radius: 10px;
}
.profile-stats {
    background-color: #f5f5f5;
    border-radius: 10px !important;
    padding: 0.2rem;
    /*align-content: center;*/
}
.profile-stat {
    /*text-align: center;*/
    margin: 0 auto;
}
.profile-stat ion-card-title {
    color: #444;
    font-size: 0.8rem;
}
.profile-stat ion-card-subtitle {
    font-size: 0.6rem;
}
.profile-info {
    text-align: left;
    margin-top: -0.2rem;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.profile-name {
    color: #3b3b3b !important;
    font-weight: 500 !important;
}
.profile-card ion-icon {
    font-size: 1.75rem;
    color: #C90B1B;
    margin-bottom: 1rem;
}
.profile-status-container {
    margin-top: 1rem;
    margin-bottom: -2rem;
}
.profile-status-container ion-card-content {
    margin-top: -1rem !important;
}
.profile-action-container {
    margin-top: -2rem;
}
.profile-status ion-card-subtitle {
    margin-top: 0.35rem;
    margin-left: 1rem;
}
.profile-action-card {
    padding-top: 0.5rem;
}
.profile-action-card ion-icon {
    font-size: 1.75rem;
    color: #C90B1B;
    margin-top: -0.3rem;
}
