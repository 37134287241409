.calculations-item ion-label {
  border-left: 2px solid var(--ion-color-primary);
  padding-left: 10px;
}

.c-wrapper {
  max-width: 960px; 

  padding-right: 10px;
  padding-left: 10px;
}

ion-fab-button[data-desc] {
  position: relative;
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 55px;
  bottom: 4px;
  background-color: var(--ion-color-primary);
  padding: 9px;
  border-radius: 15px;
  color: white;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
}