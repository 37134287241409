/* Header-container */
.header {
  position: absolute;
  width: 100%;
  height: 30%;
  left: 0px;
  top: 0px;
  background: #F7F7F7;
}

.wrapper {
  max-width: 960px; 

  padding-right: 10px;
  padding-left: 10px;
  top: 100px;
  position: relative;

}

/* Logo and Img Container*/
.header-logo-img {
  position: absolute;
  width: 100%;
  height: 60%;
  left: 0px;
  top: 20%;
  background: var(--ion-color-primary);
}

/* Logo Container */
.header-logo{
  position: absolute;
  width: 50%;
  height: 40%;
  left: 5%;
  top: 30%;
  background: var(--ion-color-primary);

}

/* Hino Logo Icon 
.header-logo-icon-red {
  position: absolute;
  width: 30%;
  height: 60%;
  left: 5px;
  top: 20%;
  background: url('../../assets/images/hino-logo-icon-red.png') no-repeat;

}*/

/* Hino Logo Text */
.header-logo-text {
  position: absolute;
  width: 70%;
  height: 150%;
  left: 5px;
  top: 35%;
  background: url('../../assets/images/hino-logo.svg') no-repeat; 
}

/* Hino Truck Image */
.header-img {
  position: absolute;
  width: 50%;
  height: 120%;
  left: 50%;
  top: 13px;
  background: url('../../assets/images/hino500.png') no-repeat center;
  background-size: contain;
}

/* Login */

.login {
  position: absolute;
  width: 100%;
  height: 30%;
  left: 0px;
  top: 40%;
  margin: auto;
  padding: 2em;
  background: white;
}

.offline-img {
  position: absolute;
  width: 25%;
  height: 25%;
  top: 30%;
  background: url('../../assets/images/offline.svg') no-repeat center;
  background-size: contain;
}

.offline-title {
  position: absolute;
  font-size: 2em;
  color: var(--ion-color-primary);
  top: 50%;
}

.offline-subtitle {
  position: absolute;
  top: 52%;
  margin: auto;
}

.offline-button {
  position: absolute;
  top: 80%;
  width: 90%;
}

.login-title {
  position: absolute;
  height: 28px;
  left: 28px;
  top: 10%;
  /*font-family: Lato;*/
  font-style: normal;
  font-weight: normal;
  font-size: 2em;
  line-height: 28px;
  color: var(--ion-color-primary);
}

.login-subtitle-black {
  position: absolute;
  height: 28px;
  left: 28px;
  right: 28px;
  top: 25%;
  /*font-family: Lato;*/
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #020202;
}

.login-email-title {
  position: absolute;
  height: 16px;
  left: 2em;
  top: 50%;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: #161616;
}
.login-email-title-error {
  position: absolute;
  height: 16px;
  left: 2em;
  top: 50%;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: var(--ion-color-primary);
}

.login-email {
  position: absolute;
  height: 12px;
  /*left: 2em;
  right: 2em;*/
  top: 70%;
  margin: 5% auto;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 12px;

  color: #161616;
}
.login-email-error {
  position: absolute;
  height: 12px;
  /*left: 2em;
  right: 2em;*/
  top: 70%;
  margin: 5% auto;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 12px;

  color: var(--ion-color-primary);
}

.login-underline {
  position: absolute;
  height: 0px;
  left: 28px;
  right: 28px;
  margin: 5% auto;
  top: 80%;
  border: 1px solid #161616;
}
.login-underline-error {
  position: absolute;
  height: 0px;
  left: 28px;
  right: 28px;
  margin: 5% auto;
  top: 80%;
  border: 1px solid var(--ion-color-primary);
}
.login-text-error {

  position: absolute;

  height: 12px;
  left: 2em;
  right: 2em;
  top: 85%;
  font-size: 15px;
  line-height: 12px;

}

/* Button-Container */
.footer {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0px;
  top: 80%;
  margin: auto;
  padding: 2em;
  background: white;
}

.footer-button {
  position: absolute;
  width: 90%;
  height: auto;
  top: 80%;
  padding: 2em;
  margin: auto;
  background: white;
}

/* Button */
.login-button {
  position: absolute;
  left: 28px;
  right: 28px;
  top: 0%;
  margin-bottom: 2em;
  bottom: 90%;
  background: var(--ion-color-primary);
  border-radius: 50px;
}

.otpContainer {
  margin: 5% auto;
  position: absolute;
  /*height: 12px;
  left: 28px;
  right: 28px;*/
  top: 70%;
}

.otpInput-4 {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: var(--ion-color-light);

}

.otpInput-4-error {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--ion-color-primary);
  background: var(--ion-color-light);
}


.otpInput-5 {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.5rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: var(--ion-color-light);

}

.otpInput-5-error {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.5rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--ion-color-primary);
  background: var(--ion-color-light);

}































.login-header-text-red {
  position: absolute;
  width: 100%;
  height: 28px;
  left: 28px;
  top: 240px;
  
  /*font-family: Lato;*/
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #C90B1B;
}
































/* Rectangle 1 */
.rectangle-1 {
  position: absolute;
  width: 167.32px;
  height: 35px;
  left: 6px;
  top: 42px;
  background: #C90B1B;
}

/* Rectangle 2 */
.rectangle-2 {
  position: absolute;
  width: 100%;
  height: 30%;
  left: 0px;
  top: 0px;
  background: #F7F7F7;
}

/* Rectangle 3 */
.rectangle-3 {
  position: absolute;
  width: 100%;
  height: 109px;
  left: 0px;
  top: 47px;
  background: #C90B1B;
}

/* Primary - 500 */
.primary-500 {
  color: #C90B1B; 
}

/* Hino Logo Icon 
.hino-logo-icon-red {
  position: absolute;
  width: 45.67px;
  height: 33.43px;
  left: 8.28px;
  top: 0px;
  background: url('../../assets/images/hino-logo-icon-red.png');
} */

/* Hino Logo Text */
.hino-logo-text {
  position: absolute;
  width: 116.95px;
  height: 28.88px;
  left: 53.95px;
  top: 0px;
  /*background: url('../../assets/images/hino-logo-text-red.png');*/
  background: url('../../assets/images/hino-logo.svg');
}

/* Login */
.login-heading {
  position: absolute;
  height: 28px;
  left: 28px;
  top: 240px;

  /*font-family: Lato;*/
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #C90B1B;
}

.login-text-red {

  position: absolute;
  width: 251px;
  height: 28px;
  left: 28px;
  top: 240px;
  
  /*font-family: Lato;*/
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: #C90B1B;
}

.login-text-black {
  position: absolute;
  height: 28px;
  left: 28px;
  top: 275px;

  /*font-family: Lato;*/
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #020202;
}

.login-email-label {
  position: absolute;
  height: 16px;
  left: 28px;
  right: 28px;
  top: 375px;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: #161616;
}
.login-email-label-error {
  position: absolute;
  height: 16px;
  left: 28px;
  right: 28px;
  top: 375px;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;

  color: #C90B1B;
}

.login-email-text {
  position: absolute;
  height: 12px;
  left: 28px;
  right: 28px;
  top: 400px;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 12px;

  color: #161616;
}
.login-email-text-error {
  position: absolute;
  height: 12px;
  left: 28px;
  right: 28px;
  top: 400px;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 12px;

  color: #C90B1B;
}

.login-email-underline {

  position: absolute;

  height: 0px;
  left: 28px;
  right: 28px;
  top: 430px;

  border: 1px solid #161616;

}
.login-email-underline-error {

  position: absolute;

  height: 0px;
  left: 28px;
  right: 28px;
  top: 430px;

  border: 1px solid #C90B1B;
}
.login-email-underline-text-error {

  position: absolute;

  height: 12px;
  left: 28px;
  right: 28px;
  top: 440px;
  font-size: 15px;
  line-height: 12px;

}

/*.login-button {
  position: absolute;
  left: 28px;
  right: 28px;
  top: 500px;
  bottom: 38px;
  background: #C90B1B;
  border-radius: 50px;
}*/

.login-logo-1 {
  position: absolute;
  width: 467.78px;
  height: 97.85px;
  left: 16.77px;
  top: 226.46px;

  background: #C90B1B;
}

.login-hino-truck {
  position: absolute;
  width: 195px;
  height: 241px;
  left: 200px;
  top: 13px;

  background: url('../../assets/images/hino500.png');
}

@media screen and (min-width: 576px) {
  
}




@media screen and (min-width: 768px) {
/* Header-container */
.header {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #F7F7F7;
}

/* Logo and Img Container*/
.header-logo-img {
  position: absolute;
  width: 100%;
  height: 33%;
  left: 0px;
  top: 33%;
  background: var(--ion-color-primary);
}

/* Logo Container */
.header-logo{
  position: absolute;
  width: 60%;
  height: 60%;
  left: 20%;
  top: 30%;
  background: var(--ion-color-primary);

}

/* Hino Logo Icon 
.header-logo-icon-red {
  position: absolute;
  width: 30%;
  height: 100%;
  left: 5px;
  top: 20%;
  background: url('../../assets/images/hino-logo-icon-red.png') no-repeat;

}*/

/* Hino Logo Text */
.header-logo-text {
  position: absolute;
  /*background-size: cover;*/
  margin-right: 40%;
  padding-right: 40%;
  /*height: 0;*/
  padding: 0; /* reset */
  /*padding-bottom: calc(100% * 100 / 5);*/
  top: 20%;
  /*background: url('../../assets/images/hino-logo-text-red.png') no-repeat;*/
  background: url('../../assets/images/hino-logo.svg') no-repeat;  
}

/* Hino Truck Image */
.header-img {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 70%;
  top: 13px;
  background: url('../../assets/images/hino500.png') no-repeat center;
  background-size: contain;
}

/* Login */

.login {
  position: absolute;
  width: 40%;
  height: 100%;
  left: 60%;
  top: 0px;
  margin: auto;
  padding: 2em;
  background: white;
}

.login-title {
  position: relative;
  top: 30%;
  /*font-family: Lato;*/
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 40px;
  color: var(--ion-color-primary);
}

.login-subtitle-black {
  position: absolute;
  height: 28px;
  top: 35%;
  /*font-family: Lato;*/
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  color: #020202;
}

.login-email-title {
  position: absolute;
  height: 16px;
  left: 2em;
  top: 50%;

  /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;

  color: #161616;
}
.login-email-title-error {
  position: absolute;
  height: 16px;
  left: 2em;
  top: 50%;

  /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;

  color: var(--ion-color-primary);
}

.login-email {
  position: absolute;
  height: 12px;
  left: 2em;
  right: 2em;
  top: 55%;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;

  color: #161616;
}
.login-email-error {
  position: absolute;
  height: 12px;
  left: 2em;
  right: 2em;
  top: 55%;

    /*font-family: Roboto;*/
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;

  color: var(--ion-color-primary);
}

.login-underline {
  position: absolute;
  height: 0px;
  left: 28px;
  right: 28px;
  top: 60%;
  border: 1px solid #161616;
}
.login-underline-error {
  position: absolute;
  height: 0px;
  left: 28px;
  right: 28px;
  top: 60%;
  border: 1px solid var(--ion-color-primary);
}
.login-text-error {

  position: absolute;

  height: 12px;
  left: 2em;
  right: 2em;
  top: 62%;
  font-size: 20px;
  line-height: 20px;

}

/* Button-Container */
.footer {
  position: absolute;
  width: 40%;
  height: 20%;
  left: 60%;
  top: 75%;
  margin: auto;
  padding: 2em;
  background: white;
}

/* Button */
.login-button {
  position: absolute;
  top: 60%;
  /*bottom: 60%;*/
  background: var(--ion-color-primary);
  border-radius: 50px;
}

.otpContainer {
  margin: 5% auto;
  position: absolute;
  /*height: 12px;
  left: 28px;
  right: 28px;*/
  top: 60%;
  background: white;
}

.otpInput-4 {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: white;
}

.otpInput-4-error {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--ion-color-primary);
  background-color: white;
}


.otpInput-5 {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.5rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: white;
}



}

