.homePage ion-toolbar {
    --border-style: none;
}

.h-wrapper {
    max-width: 960px; 
  
    padding-right: 10px;
    padding-left: 10px;

}
.logo {
    margin-top: 0.25rem;
    color: var(--ion-color-primary);
}

.circle-primary {
  display: flex;
  width: 50px;
  height: 50px;
  background-color: var(--ion-color-primary);
  border-radius: 50%;
}

.swiper-button-next {
  color: var(--ion-color-primary)!important;
}

.swiper-button-prev {
  color: var(--ion-color-primary)!important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*box-shadow: 0 10px 30px rgb(0 0 0 / 15%);*/
	margin:0 auto;
}

.dealership-slide {
  background: var(--ion-color-light);
}

.text {
  margin: auto;
}

.text-light {
  margin: auto;
  color: white;
}

.home-list ion-item {
    background: #fbfbfb;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #E4E4E4;
    padding: 0;
}
.home-list ion-item p {
    margin: 0;
}
.home-list ion-item ion-icon {
    border-radius: 5px;
    padding: 0.2rem;
    font-size: 1.4rem;
    margin-right: 1.2rem;
    --setting-item-color: white;
    background-color: var(--setting-item-color);
    /* color: #e92e2e; */
}
  .home-card, .home-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .home-card-content ion-button {
    height: 1.5rem;
    font-size: 0.8rem;
  }
  .home-card-content {
    background-color: #eee;
  }
  .home-card-content-hps {
    background-color: var(--ion-color-light);
    background-size: 100%;
    background-repeat: no-repeat!important;
    background-size: cover!important;
  /*width: 105vw!important;
  height: 100vh!important;
    margin-left: -10%!important;*/
    background-attachment: fixed!important;
  /*margin-top: -10%!important;*/
    background-position: center center!important;
  /* background: url('../assets/images/hino_dealer.png') no-repeat center; */
  }
  /*.home-card-content ion-card-subtitle {
    color: rgb(78, 78, 78); 
  } */
  .home-card img {
    /* border-radius: 5px; */
    padding: 1rem;
  }
  .home-slider {
    margin-top: 1rem;
  }
  .home-slider ion-slide {
    width: 60%;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-slider ion-card {
    background: linear-gradient(180deg, #464646 0%, #020202 100%);
    box-shadow: 2px 6px 7px rgba(188, 188, 188, 0.25);
    border-radius: 10px;
  }
  
  .guides-section {
    padding: 0px 0rem 0px 0rem;
    color: white;
    margin: 5px;
    background: linear-gradient(180deg, var(--ion-color-primary) 0%, #720303 100%);
    box-shadow: 2px 6px 7px rgba(188, 188, 188, 0.25);
    border-radius: 0px;
  }
  .guides-section h4 {
    margin-left: 1.5rem;
  }
  .account-section {
    /*padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;*/
    padding: 2rem;
    margin: 5px;
    /*background-color: var(--ion-color-primary);
    border-radius: 22px;*/
    margin: 5px;
    background: linear-gradient(180deg, #BE0A18 0%, #750404 100%);
    box-shadow: 2px 6px 7px rgba(188, 188, 188, 0.25);
    border-radius: 10px;
    color: white;
    /*margin-left: 5px;
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 0px;*/
  }
  .account-section h4, .account-section ion-card-subtitle {
    margin-left: 1.5rem;
  }
  .account-section ion-card-subtitle {
    color: yellow;
    font-weight: 800;
  }

  .models {
    margin-top: -1.2rem;
 }
  .model-card {
    width: 100%;
    height: 12rem;
    /*background: linear-gradient(180deg, #BE0A18 0%, #750404 100%);*/
    background: linear-gradient(180deg, #464646 0%, #020202 100%);
    box-shadow: 2px 6px 7px rgba(188, 188, 188, 0.25);
    border-radius: 10px;
 }
  .model-card img {
    height: 100%;
    width: 100%;
 }
  .model-card .model-details {
    position: absolute;
    bottom: 2rem;
    right: 0px;
    margin-left: 1rem;
    /*background-color: rgba(201, 11, 27, 0.6);*/
    background-color: var(--ion-color-primary);
    padding: 0.5rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
 }

 /*.model-details {
  position: relative;
  bottom: 4rem;
  margin-left: 1rem;
  background-color: rgba(201, 11, 27, 0.6);
  padding: 0.5rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
 } */

  .model-card ion-card-title {
    color: white;
    font-size: 0.9rem;
 }
  .model-card ion-note {
    color: white;
    font-size: 0.6rem;
 }

/*.model-details ion-card-title {
  color: white;
  font-size: 0.9rem;
}
.model-details ion-note {
  color: white;
  font-size: 0.6rem;
}*/

ion-slides {

	height: 100%;
}

.slide-grid {

	height: 100%;
}

.slide-main-image {

	height: 5rem !important;
}

.slide-buttons {

	position: absolute;
	bottom: 2rem;
	z-index: 10;

	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	align-items: center;
	align-content: center;
}

.slide-content-container {

	margin-top: -4rem;
}

.slide-content {
	
	margin: 0 auto;
	/* margin-top: 5rem; */
	color: var(--ion-color-primary);
	/* background-color: var(--ion-color-primary); */
	/* color: white; */
	border: 2px solid rgb(228, 228, 228);
	border-radius: 15px;
	padding: 3rem;
	/* padding-left: 3rem; */
	/* padding-right: 3rem; */
}

.slide-content p {

	color: rgb(161, 161, 161);
}

/*ion-item {
  --ion-background-color: #e9ebee !important;
  --ion-border-radius: 10px;
}*/

.cards {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /*filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0px 15px 50px rgba(213, 54, 54, 0.15));*/
}

.saved-spec-card {
  background: linear-gradient(180deg, #464646 0%, #020202 100%);
  /*filter: drop-shadow(0px 15px 50px rgba(213, 54, 54, 0.15));*/
}

.card {
  margin: 30px auto;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  /*background: linear-gradient(180deg, #464646 0%, #020202 100%);*/
  background: linear-gradient(180deg, #BE0A18 0%, #750404 100%);
  /*box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);*/
  /*box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);*/
  cursor: pointer;
  transition: 0.4s;
}

.card .card-image {
  width: inherit;
  height: inherit;
  border-radius: 50px;
}

.card .card-image img {
  width: inherit;
  height: inherit;
  border-radius: 10px;
  object-fit: cover;
}

.card .card-title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 15px;
  margin-top: -15px;
  height: 40px;
  color: white;
}

.card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(213,54,54,0.25), 
    -5px -5px 30px 15px rgba(213,54,54,0.22);
}

.card:focus {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(213,54,54,0.25), 
    -5px -5px 30px 15px rgba(213,54,54,0.22);
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

.title-yellow {
  color: var(--ion-color-warning);
}

@media all and (max-width: 500px) {
  .cards {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
}


/*
.card {
  margin: 30px auto;
  width: 300px;
  height: 300px;
  border-radius: 40px;
  background-image: url('../assets/...');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
  transition: 0.4s;
}
*/

.series-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.series-title {
  position: relative;
  width: 100%;
  /*height: 30px;
  left: 23px;*/
  /*top: 46px;*/

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;

  color: #000000;
}

/*.series-title-underline {
  position: relative;
  width: 100%;
  height: 6px;
  background: #E9E9E9;
  color: #E9E9E9;
  border-radius: 10000px;
}*/

.series-title-underline {
  position: relative;
  width: 100%;
  height: 6px;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary);
  border-radius: 10000px;
}

.series-list {
  position: relative;
  
  margin: 30px auto;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
  width: 100%;
  filter: drop-shadow(0px 15px 50px rgba(213, 54, 54, 0.15));
}

.series-avatar {
  margin: 30px auto;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
}

.series-avatar .series-image {
  width: inherit;
  height: inherit;
  border-radius: 10px;
}

.series-avatar .series-image img {
  width: inherit;
  height: inherit;
  object-fit: cover;

  border: 4px solid var(--ion-color-primary);
  box-sizing: border-box;
}
  