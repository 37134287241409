/*
 * Material Design
 */

 .truck-list-filter .md ion-toolbar ion-button {
    text-transform: capitalize;
    letter-spacing: 0;
  }
  
  .truck-list-filter .md ion-checkbox {
    --background-checked: transparent;
    --border-color: transparent;
    --border-color-checked: transparent;
    --checkmark-color: var(--ion-color-primary);
  }
  
  .truck-list-filter .md ion-list {
    background: inherit;
  }
  
  
  /*
   * iOS
   */
  
  .truck-list-filter .ios ion-list-header {
    margin-top: 10px;
  }
  
  .truck-list-filter .ios ion-label {
    color: var(--ion-color-primary);
  }

  .truck-list-item ion-label {
    border-left: 2px solid var(--ion-color-primary);
    padding-left: 10px;
  }