
body {
    margin: 0;
    background-color: #525659;
    font-family: Segoe UI, Tahoma, sans-serif;
  }
  
  .guidelines header {
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;
  }
  
  .guidelines header h1 {
    font-size: inherit;
    margin: 0;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    overflow: hidden; 
  }
  
  .container__load {
    margin-top: 1em;
    color: white;
  }
  
  .container__document {
    margin: 1em 0;
  }
  
  .container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .container__document .react-pdf__Page {
    max-width: calc(100% - 2em);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
  }
  
  .container__document .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
  }
  
  .container__document .react-pdf__message {
    padding: 20px;
    color: white;
  }



  .zoom-controls {
    /*display: flex; 
    padding: 0.25rem; 
    gap: 0.5rem; 
    justify-content: center; 
    align-items: center; 
    border-bottom-width: 1px; 
    font-size: 0.875rem;
    line-height: 1.25rem; 
    color: #4B5563; 
    background-color: #F3F4F6; */

    display: flex; 
padding: 0.75rem; 
gap: 1rem; 
border-bottom-width: 1px; 
  }

  .zoom-out {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; 
    padding-left: 0.75rem;
    padding-right: 0.75rem; 
    margin-right: -0.5rem; 
    color: #111827; 
  }

  .current-zoom {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; 
    padding-left: 0.75rem;
    padding-right: 0.75rem; 
    border-radius: 9999px; 
    border-width: 1px; 
    width: 4rem; 
    text-align: center; 
    background-color: #ffffff; 
  }

  .zoom-in {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; 
    padding-left: 0.75rem;
    padding-right: 0.75rem; 
    margin-left: -0.5rem; 
    color: #111827; 
  }